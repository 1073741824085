import Image, { ImageProps } from 'next/image';
import React, { CSSProperties } from 'react';

import { getImageData } from 'src/utils/getImageData';

export const defaultStyle: CSSProperties = { width: '100%', height: 'auto', display: 'block' };

export interface NextImageProps extends ImageProps {
	background?: boolean;
}

export const NextImage: React.FC<NextImageProps> = ({
	alt = '',
	background = true,
	style = {},
	...props
}) => {
	const image = getImageData(props.src as string);

	const localStyle = background
		? {
				...defaultStyle,
				background: `url(${image?.dominantColorDataUrl ?? image?.blurDataUrl})`,
				...style,
			}
		: { ...defaultStyle, ...style };

	return (
		<Image
			alt={alt}
			style={localStyle}
			width={image?.width}
			height={image?.height}
			// placeholder="blur" // TODO: Enable once: https://github.com/vercel/next.js/issues/40644 gets resolved.
			// blurDataURL={image?.dominantColorDataUrl ?? image?.blurDataUrl}
			sizes="100vw"
			{...props}
		/>
	);
};
