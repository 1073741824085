import manifest from 'image-manifest.json';

export type ImageType = 'png' | 'jpg' | 'jpeg' | 'gif';

export interface ManifestImage {
	blurDataUrl: string;
	dominantColorDataUrl: string;
	src: string;
	width: number;
	height: number;
	type?: ImageType;
}

export type Manifest = Record<string, ManifestImage>;

export const getImageData = (imageKey: string): ManifestImage | null =>
	// @ts-ignore
	(manifest[imageKey] as ManifestImage) ?? null;
